<template>
  <v-card>
    <v-btn
      fab
      x-small
      color="error"
      class="delete-btn"
      @click="removeSelected()"
    >
      <v-icon>
        mdi-delete
      </v-icon>
    </v-btn>
    <div class="d-flex flex-no-wrap justify-start" @click="updateSelected()">
      <v-avatar
        tile
        left
        size="100"
        color="#F7F7F7"
        class="ma-3 sponsor-image"
      >
        <v-img
          v-if="sponsorData.image"
          :src="sponsorData.image"
          contain
          max-height="70%"
          @error="imageErrorHandler()"
        />

        <span
          v-else
          class="display-2 white--text text-center"
        >
          <v-icon>
            mdi-trophy
          </v-icon>
        </span>
      </v-avatar>
      <div>
        <v-card-title v-text="sponsorData.name" />
      </div>
    </div>
  </v-card>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  props: {
    sponsorData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    updateSelected () {
      this.$emit('updateSelected', this.sponsorData._id)
    },
    removeSelected () {
      this.$emit('removeSelected', this.sponsorData._id)
    },
    imageErrorHandler () {
      this.sponsorData.image = imageOffIcon
    }
  }
}
</script>

<style scoped>
  .delete-btn {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .delete-btn:hover {
    transform: scale(1.1);
  }

  .sponsor-image {
    border: 1px solid #BDBDBD !important;
    border-radius: 3px !important;
  }
</style>
